// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hostname-ip-js": () => import("./../../src/pages/hostname-ip.js" /* webpackChunkName: "component---src-pages-hostname-ip-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-io-scaling-js": () => import("./../../src/pages/io-scaling.js" /* webpackChunkName: "component---src-pages-io-scaling-js" */),
  "component---src-pages-remote-support-js": () => import("./../../src/pages/remote-support.js" /* webpackChunkName: "component---src-pages-remote-support-js" */)
}

